import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  return (
    <Layout path={location.pathname} title="קטע מספר 9: מבארי לנחל עוז">
      <SectionContent title="קטע מספר 9: מבארי לנחל עוז">
        <div>
          <p>
            <strong>אורך הקטע: 7 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            יוצאים מחנות לה-מדווש לכיוון צפון-מזרח במקביל לכביש הכניסה לקיבוץ.
            במפגש עם דרך עפר משמאל (הכניסה לסינגל בארי) פונים שמאלה בכוון כללי
            צפון-מערב ורצים לאורך הגדר התוחמת את שמורת בארי עד למפגש עם דרך
            אספלט בק&quot;מ 2. על הדרך פונים שמאלה עד לצמת T בסמוך לגלעד 'דרך
            השישה'. בצומת פונים ימינה עם דרך האספלט, וממשיכים עם הדרך עד הכניסה
            לקיבוץ נחל-עוז. מול שער הקיבוץ פונים ימינה (צפונה) עוד 400 מטר עד
            לתחנת ההחלפה ב'פינת דניאל' .
          </p>
          <p>
            <strong>טופוגרפיה</strong>: מגמת ירידה מתונה
          </p>
          <p>
            <strong>מסלול</strong>: דרך עפר ודרך אספלט.
          </p>
          <p>
            <strong>בטיחות</strong>: ערנות לרכבים החקלאים הנוסעים בדרך.
          </p>
          <p>
            <strong>תחנת החלפה</strong> : 'פינת המרגוע ע&quot;ש דניאל' בחורשת
            האקליפטוסים שבין כביש לכניסה לקיבוץ נחל עוז לכביש הראשי (כביש 35).
          </p>
        </div>
        <div>
          <p>
            <iframe
              src="//www.gpsies.com/mapOnly.do?fileId=fvdgoudczfiwrqkz"
              width="100%"
              height="400"
              scrolling="no"
              title="sectionIframe"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
